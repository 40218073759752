import React from 'react'

function PreLoader() {
  return (
    <>
      <div className="preloader">
        <div id="loading-center">
        </div>
      </div>
    </>
  )
}

export default PreLoader