import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from "../components/layouts/NavBar";
import Footer from "../components/layouts/Footer";
import Header from "../components/layouts/Header";
import { apiUrl } from '../components/basics/BaseUrl';

function Dashboard() {

  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  // [{
  //   id: "1", category_image: "../assets/images/category-img/dairy.png",
  //   category_name: 'Dairy Products'
  // },
  // {
  //   id: "2", category_image: "../assets/images/category-img/cereals.png",
  //   category_name: 'Cereals'
  // },
  // {
  //   id: "3", category_image: "../assets/images/category-img/vegetables.png",
  //   category_name: 'Vegetables'
  // },
  // {
  //   id: "4", category_image: "../assets/images/category-img/fruits.png",
  //   category_name: 'Fruits'
  // },]

  const fetchSubCategories = async () => {
    try {
      const response = await fetch(`${apiUrl}/products/`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        const sortedData = data.sort((a, b) => a.id - b.id);
        setCategories(sortedData);
        console.log('success', 'Subcategories loaded successfully');
      } else {
        console.log('danger', 'Failed to load subcategories');
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  useEffect(() => {
    fetchSubCategories();
  }, []);

  const GoListCategory = () => {
    navigate('/listproduct');
  };

  return (
    <>
      <div id="wrapper">
        <div className="iq-sidebar sidebar-default">
          <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
            <a className="header-logo m-auto p-auto">
              <h5 className="logo-title light-logo text-teal ">Green</h5>
              <h5 className="logo-title light-logo text-orange">-Mark</h5>
            </a>
            <div className="iq-menu-bt-sidebar">
              <i className="las la-bars wrapper-menu"></i>
            </div>
          </div>
          <div className="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <nav className="iq-sidebar-menu ">
              <ul id="iq-sidebar-toggle" className="iq-menu">
                <li className="active">
                  <a href="/dashboard" className="svg-icon">
                    <svg className="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    <span className="ml-4">Dashboards</span>
                  </a>
                </li>
                <li className="">
                  <a href="#category" className="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    <span className="ml-4">Categories</span>
                    <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="category" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li className="">
                      <a href="/addcategory">
                        <i className="las la-minus"></i><span>Add Category</span>
                      </a>
                    </li>
                    <li className="">
                      <a href="/listcategory">
                        <i className="las la-minus"></i><span>List of Category</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg className="svg-icon" id="p-dash-sub" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                      <line x1="7" y1="7" x2="17" y2="7"></line>
                      <line x1="7" y1="12" x2="17" y2="12"></line>
                      <line x1="7" y1="17" x2="17" y2="17"></line>
                    </svg>
                    <span class="ml-4">Sub-Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addsubcategory">
                        <i class="las la-minus"></i><span>Add Sub-Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listsubcategory">
                        <i class="las la-minus"></i><span>Sub-Category List</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="">
                  <a href="#product" className="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg className="svg-icon" id="p-dash-product" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M3 9L12 2l9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                    <span className="ml-4">Products</span>
                    <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="product" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li className="">
                      <a href="/addproduct">
                        <i className="las la-minus"></i><span>Add Product</span>
                      </a>
                    </li>
                    <li className="">
                      <a href="/listproduct">
                        <i className="las la-minus"></i><span>List of Products</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="/listorder" class="svg-icon">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span class="ml-4">Orders</span>
                  </a>
                </li>
                <li className="">
                  <a href="/customer" className="svg-icon">
                    <svg className="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span className="ml-4">Customers</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <NavBar />
        <Header />
        <div className="content-page">
          <div className="container-fluid">
            <div className="row mt-4">
              <div className="col-lg-4">
                <div className="card card-transparent card-block card-stretch card-height border-none">
                  <div className="card-body p-0 mt-lg-2 mt-0">
                    <h3 className="mb-3 text-dark">Welcome,</h3>
                    <p className="mb-0 mr-4">
                      Experience the difference that nature’s best can make in your life with our range of organic products.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <div className="card card-block card-stretch card-height">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-4 card-total-sale">
                          <div className="icon iq-icon-box-2 bg-success-light">
                            <i className="fas fa-chart-line"></i>
                          </div>
                          <div>
                            <p className="mb-2">Total Sales</p>
                            <h4>31.50 %</h4>
                          </div>
                        </div>
                        <div className="progress">
                          <div className="progress-bar progress-bar-striped progress-bar-animated bg-teal" role="progressbar" style={{ width: "80%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="card card-block card-stretch card-height">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-4 card-total-sale">
                          <div className="icon iq-icon-box-2 bg-primary-light">
                            <i className="fas fa-tag"></i>
                          </div>
                          <div>
                            <p className="mb-2">Total Cost</p>
                            <h4>₹ 45,983</h4>
                          </div>
                        </div>
                        <div className="progress">
                          <div className="progress-bar progress-bar-striped progress-bar-animated bg-skyblue" role="progressbar" style={{ width: "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="card card-block card-stretch card-height">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-4 card-total-sale">
                          <div className="icon iq-icon-box-2 bg-orange-light">
                            <i className="fas fa-shopping-cart"></i>
                          </div>
                          <div>
                            <p className="mb-2">Product Sold</p>
                            <h4>369 /-</h4>
                          </div>
                        </div>
                        <div className="progress">
                          <div className="progress-bar progress-bar-striped progress-bar-animated bg-orange" role="progressbar" style={{ width: "70%" }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-around">
              <div className="col-xl-6 col-md-12">
                <div className="col-lg-12 col-md-12">
                  <div className="card card-block card-stretch card-height">
                    <div className="card-header d-flex justify-content-around">
                      <div className="header-title">
                        <h4 className="card-title">Order Summary</h4>
                      </div>
                      {/* <div className="card-header-toolbar d-flex align-items-center">
                      <select className="custom-select" >
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                      </select>
                    </div> */}
                    </div>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center justify-content-around mt-2">
                        <div className="d-flex align-items-center progress-order-left">
                          <div className="progress progress-round m-0 orange conversation-bar" data-percent="46" >
                            <span className="progress-left">
                              <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right">
                              <span className="progress-bar"></span>
                            </span>
                            <b className="progress-value text-orange"><i className='fas fa-shopping-cart ml-2'></i></b>
                          </div>
                          <div className="progress-value ml-3 pr-5 border-right">
                            <h5>128</h5>
                            <p className="mb-0">No. of Orders</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center progress-order-right">
                          <div className="progress progress-round m-0 primary conversation-bar" data-percent="46" >
                            <span className="progress-left">
                              <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right">
                              <span className="progress-bar"></span>
                            </span>
                            <b className="progress-value text-primary"><i className='fas fa-shopping-cart ml-2'></i></b>
                          </div>
                          <div className="progress-value ml-3">
                            <h5>₹ 19,847</h5>
                            <p className="mb-0">Total Amount</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div id="layout1-chart-5"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="card card-block card-stretch card-height">
                    <div className="card-header d-flex justify-content-around">
                      <div className="header-title">
                        <h4 className="card-title">User Summary</h4>
                      </div>
                      {/* <div className="card-header-toolbar d-flex align-items-center">
                      <select className="custom-select" >
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                      </select>
                    </div> */}
                    </div>
                    <div className="card-body">
                      <div className="d-flex flex-wrap align-items-center justify-content-around mt-2">
                        <div className="d-flex align-items-center progress-order-left">
                          <div className="progress progress-round m-0 orange conversation-bar" data-percent="46" >
                            <span className="progress-left">
                              <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right">
                              <span className="progress-bar"></span>
                            </span>
                            <div className="progress-value text-orange"><i className="fas fa-user ml-2"></i></div>
                          </div>
                          <div className="progress-value ml-3 pr-5 border-right">
                            <h5>13</h5>
                            <p className="mb-0">No. of Customers</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center ml-5 progress-order-right">
                          <div className="progress progress-round m-0 primary conversation-bar" data-percent="46" >
                            <span className="progress-left">
                              <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right">
                              <span className="progress-bar"></span>
                            </span>
                            <d className="progress-value text-primary"><i className="fas fa-user ml-2"></i></d>
                          </div>
                          <div className="progress-value ml-3">
                            <h5>26</h5>
                            <p className="mb-0">No. of Guests</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div id="layout1-chart-5"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <div className="header-title text-center mx-auto">
                      <h4>
                        <span className="card-title text-orange">Our Available Products</span>
                        <span className="card-title text-danger"> - </span>
                        <span className="card-title text-teal">The true organic</span>
                      </h4>
                    </div>
                  </div>
                  <div className="card-body mx-auto text-center">
                    <div className="bd-example">
                      <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                          {categories.map((category, index) => (
                            <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={category.id}>
                              <img
                                onClick={GoListCategory}
                                src={`data:image/jpeg;base64,${category.product_image}`}
                                className="d-block img-fluid carousel-img"
                                alt={category.product_name}
                              />
                              <hr className='m-3'/>
                              <h4 className="text-orange text-uppercase">{category.product_name}</h4>
                            </div>
                          ))}
                        </div>
                      </div>
                      <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon p-3 bg-light rounded" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                        <span className="carousel-control-next-icon p-3 bg-light rounded" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
