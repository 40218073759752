import React, { useState, useEffect } from 'react';
import Footer from '../../components/layouts/Footer';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import { apiUrl } from '../../components/basics/BaseUrl';
import axios from 'axios';
import Preloader from '../../components/layouts/Header';

function ListOrder() {

    const [cartDetails, setCartDetails] = useState([]);
    const [orders, setOrders] = useState([]);
    const [deliveryDetails, setDeliveryDetails] = useState([]);

    const [searchCart, setSearchCart] = useState('');
    const [searchOrder, setSearchOrder] = useState('');
    const [searchDelivery, setSearchDelivery] = useState('');

    const [itemsPerPageCart, setItemsPerPageCart] = useState(5);
    const [itemsPerPageOrder, setItemsPerPageOrder] = useState(5)
    const [itemsPerPageDelivery, setItemsPerPageDelivery] = useState(5);

    const [currentPageCart, setCurrentPageCart] = useState(1);
    const [currentPageOrder, setCurrentPageOrder] = useState(1);
    const [currentPageDelivery, setCurrentPageDelivery] = useState(1);

    const totalPagesCart = Math.ceil(cartDetails.length / itemsPerPageCart);
    const totalPagesOrder = Math.ceil(orders.length / itemsPerPageOrder);
    const totalPagesDelivery = Math.ceil(deliveryDetails.length / itemsPerPageDelivery);

    const [alert, setAlert] = useState({ type: '', message: '', visible: false });
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('cart');

    const showAlert = (type, message) => {
        setAlert({ type, message, visible: true });
        setTimeout(() => setAlert({ ...alert, visible: false }), 2000);
    };

    useEffect(() => {
        fetchCartDetails();
        fetchOrderDetails();
        fetchDeliveryDetails();
    }, []);

    const fetchCartDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/getcartdetails/`, {
                headers: {
                    accept: 'application/json',
                },
            });
            const sortedDetails = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
            setCartDetails(sortedDetails);
            showAlert('success', 'Cart details fetched successfully');
        } catch (error) {
            console.error("Error fetching cart details:", error);
            showAlert('danger', 'Error fetching cart details');
        } finally {
            setLoading(false);
        }
    };

    const fetchOrderDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/get_order_summary`, {
                headers: {
                    accept: 'application/json',
                },
            });
            const sortedDetails = response.data.sort((a, b) => new Date(b["Order Date"]) - new Date(a["Order Date"]));
            setOrders(sortedDetails);
            showAlert('success', 'Order details fetched successfully');
        } catch (error) {
            console.error("Error fetching order details:", error);
            showAlert('danger', 'Error fetching order details');
        } finally {
            setLoading(false);
        }
    };

    const fetchDeliveryDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/get_deliverd_details`, {
                headers: {
                    accept: 'application/json',
                },
            });
            const sortedDetails = response.data.sort((a, b) => new Date(b["Delivery Date"]) - new Date(a["Delivery Date"]));
            setDeliveryDetails(sortedDetails);
            showAlert('success', 'Delivery details fetched successfully');
        } catch (error) {
            console.error("Error fetching delivery details:", error);
            showAlert('danger', 'Error fetching delivery details');
        } finally {
            setLoading(false);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        if (tab === 'cart') {
            fetchCartDetails();
        };
        if (tab === 'order') {
            fetchOrderDetails();
        };
        if (tab === 'deliver') {
            fetchDeliveryDetails();
        };
    };
    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options);
    };

    const paginate = (data, currentPage, itemsPerPage) => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return data.slice(startIndex, startIndex + itemsPerPage);
    };

    const handleSearchCartChange = (e) => {
        setSearchCart(e.target.value);
        setCurrentPageCart(1);
    };

    const handlePageChangeCart = (newPage) => {
        setCurrentPageCart(newPage);
    };

    const filteredCartDetails = cartDetails.filter(item =>
        item.mobile.includes(searchCart) ||
        formatDate(item.date).includes(searchCart)
    );

    const currentCartDetails = paginate(filteredCartDetails, currentPageCart, itemsPerPageCart);

    const handleSearchOrderChange = (e) => {
        setSearchOrder(e.target.value);
        setCurrentPageOrder(1);
    };

    const handlePageChangeOrder = (newPage) => {
        setCurrentPageOrder(newPage);
    };

    const filteredOrders = orders.filter(order =>
        order["Customer Number"].includes(searchOrder) ||
        order["Order ID"].toString().includes(searchOrder) ||
        formatDate(order["Order Date"]).includes(searchOrder)
    );

    const currentOrderDetails = paginate(filteredOrders, currentPageOrder, itemsPerPageOrder);

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [modalData, setModalData] = useState({
        customerMobile: '',
        orderId: '',
        status: 'Delivered',
        deliveryDate: ''
    });

    const openModal = (order) => {
        setSelectedOrder(order);
        setModalData({
            customerMobile: order["Customer Number"],
            orderId: order["Order ID"],
            status: 'Delivered',
            deliveryDate: ''
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`${apiUrl}/update status`, {
                customer_mobile: modalData.customerMobile,
                order_id: modalData.orderId,
                status: modalData.status,
                delivered_date: modalData.deliveryDate
            }, {
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            showAlert('success', 'Order marked as delivered');
            fetchOrderDetails();
            setSelectedOrder(null);
            window.$('#deliveryModal').modal('hide');
        } catch (error) {
            console.error("Error updating delivery status:", error);
            showAlert('danger', 'Error updating delivery status');
        }
    };

    const handleSearchDeliveryChange = (e) => {
        setSearchDelivery(e.target.value);
        setCurrentPageDelivery(1);
    };

    const handlePageChangeDelivery = (newPage) => {
        setCurrentPageDelivery(newPage);
    };

    const filteredDeliveryDetails = deliveryDetails.filter(item =>
        item["Customer Number"].includes(searchDelivery) ||
        formatDate(item["Delivery Date"]).includes(searchDelivery)
    );

    const currentDeliveryDetails = paginate(filteredDeliveryDetails, currentPageDelivery, itemsPerPageDelivery);

    return (
        <>
            <div id="wrapper">
                <div className="iq-sidebar sidebar-default">
                    <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
                        <a className="header-logo m-auto p-auto">
                            <h5 className="logo-title light-logo text-teal ">Green</h5>
                            <h5 className="logo-title light-logo text-orange">-Mark</h5>
                        </a>
                        <div className="iq-menu-bt-sidebar">
                            <i className="las la-bars wrapper-menu"></i>
                        </div>
                    </div>
                    <div className="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        <nav className="iq-sidebar-menu ">
                            <ul id="iq-sidebar-toggle" className="iq-menu">
                                <li className="">
                                    <a href="/dashboard" className="svg-icon">
                                        <svg className="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                        </svg>
                                        <span className="ml-4">Dashboards</span>
                                    </a>
                                </li>
                                <li className="">
                                    <a href="#category" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                        <span className="ml-4">Categories</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="category" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/addcategory">
                                                <i className="las la-minus"></i><span>Add Category</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/listcategory">
                                                <i className="las la-minus"></i><span>List of Category</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash-sub" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="7" y1="7" x2="17" y2="7"></line>
                                            <line x1="7" y1="12" x2="17" y2="12"></line>
                                            <line x1="7" y1="17" x2="17" y2="17"></line>
                                        </svg>
                                        <span class="ml-4">Sub-Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addsubcategory">
                                                <i class="las la-minus"></i><span>Add Sub-Category</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listsubcategory">
                                                <i class="las la-minus"></i><span>Sub-Category List</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="">
                                    <a href="#product" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash-product" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M3 9L12 2l9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9z"></path>
                                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                        </svg>
                                        <span className="ml-4">Products</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="product" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/addproduct">
                                                <i className="las la-minus"></i><span>Add Product</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/listproduct">
                                                <i className="las la-minus"></i><span>List of Products</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="active">
                                    <a href="/listorder" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="9" cy="21" r="1"></circle>
                                            <circle cx="20" cy="21" r="1"></circle>
                                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                        </svg>
                                        <span class="ml-4">Orders</span>
                                    </a>
                                </li>
                                <li className="">
                                    <a href="/customer" className="svg-icon">
                                        <svg className="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="9" cy="7" r="4"></circle>
                                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                        </svg>
                                        <span className="ml-4">Customers</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <NavBar />
                <Header />
                <div className="content-page">
                    <div className='container-fluid add-form-list mt-4'>
                        <div className='card'>
                            <div className="card-header">
                                <div className="header-title d-flex flex-wrap justify-content-between align-items-center">
                                    <h4 className="card-title text-skyblue my-2">Order Management</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                <ul className="nav nav-pills mb-3 nav-fill" id="pills-tab-1" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active text-dark font-weight-bolder font-size-20" id="pills-cart-tab-fill" data-toggle="pill"
                                            href="#pills-cart-fill" role="tab" aria-controls="pills-cart" aria-selected="true" onClick={() => handleTabChange('cart')}>In Cart</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-dark font-weight-bolder font-size-20" id="pills-order-tab-fill" data-toggle="pill"
                                            href="#pills-order-fill" role="tab" aria-controls="pills-order" aria-selected="false" onClick={() => handleTabChange('order')}>Ordered</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-dark font-weight-bolder font-size-20" id="pills-deliver-tab-fill" data-toggle="pill"
                                            href="#pills-deliver-fill" role="tab" aria-controls="pills-deliver" aria-selected="false" onClick={() => handleTabChange('deliver')}>Delivered</a>
                                    </li>
                                </ul>
                                <div className="card-body">
                                    <div className="tab-content" id="pills-tabContent-1">

                                        {activeTab === 'cart' && (
                                            <div className="tab-pane fade active show" id="pills-cart-fill" role="tabpanel" aria-labelledby="pills-cart-tab-fill">
                                                <div className='row p-3 mt-2'>
                                                    <div className="col-md-8 input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="ri-search-line"></i></span>
                                                        </div>
                                                        <input type="text" className="form-control" placeholder="Search by Mobile Number / Cart Added Date"
                                                            name="cart" value={searchCart} onChange={handleSearchCartChange} />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <select id="itemsPerPage" className="custom-select" value={itemsPerPageCart} onChange={(e) => setItemsPerPageCart(e.target.value)}>
                                                            <option value="5">05 data / page</option>
                                                            <option value="10">10 data / page</option>
                                                            <option value="15">15 data / page</option>
                                                            <option value="20">20 data / page</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="table-responsive rounded mb-3">
                                                        {loading ? (
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <Preloader />
                                                            </div>
                                                        ) : (
                                                            <table className="data-table table mb-0 tbl-server-info">
                                                                <thead className="bg-white m-0 text-center">
                                                                    <tr className="ligth ligth-data">
                                                                        <th>User Type</th>
                                                                        <th>Mobile Number</th>
                                                                        <th>Cart Added</th>
                                                                        <th>Total Amount</th>
                                                                        <th>Cart Details</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ligth-body m-0 text-center">
                                                                    {currentCartDetails.length > 0 ? (
                                                                        currentCartDetails.map(item => (
                                                                            <tr key={item.customer_id}>
                                                                                <td>{item.user_type}</td>
                                                                                <td> {item.mobile}</td>
                                                                                <td>{formatDate(item.date)}</td>
                                                                                <td>₹ {item.amount}</td>
                                                                                <td>
                                                                                    <a className='font-weight-bold badge badge-orange' title='View Cart Details'
                                                                                        href={`/cartoverview?mobile=${encodeURIComponent(item.mobile)}`}>
                                                                                        View More
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="5">
                                                                                <h5 className='text-pink mt-3'>Cart is Empty<i className='fas fa-shopping-cart ml-5'></i></h5>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        )}
                                                    </div>

                                                    <div className="d-flex flex-wrap justify-content-end mx-5">
                                                        <div className="p-3">
                                                            <button className="badge badge-secondary mr-1" disabled={currentPageCart === 1} onClick={() => handlePageChangeCart(currentPageCart - 1)}>
                                                                <i className="fas fa-arrow-left"></i>
                                                            </button>
                                                            <button className="badge badge-secondary ml-1" disabled={currentPageCart === totalPagesCart} onClick={() => handlePageChangeCart(currentPageCart + 1)}>
                                                                <i className="fas fa-arrow-right"></i>
                                                            </button>
                                                        </div>
                                                        <div className="p-3">
                                                            Page {currentPageCart} of {totalPagesCart}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {activeTab === 'order' && (
                                            <div className="tab-pane fade" id="pills-order-fill" role="tabpanel" aria-labelledby="pills-order-tab-fill">
                                                <div className='row p-3 mt-2'>
                                                    <div className="col-md-8 input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="ri-search-line"></i></span>
                                                        </div>
                                                        <input type="text" className="form-control" placeholder="Search by Mobile Number / Order Date"
                                                            name="order" value={searchOrder} onChange={handleSearchOrderChange} />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <select id="itemsPerPageOrder" className="custom-select" value={itemsPerPageOrder} onChange={(e) => setItemsPerPageOrder(parseInt(e.target.value))}>
                                                            <option value="5">05 data / page</option>
                                                            <option value="10">10 data / page</option>
                                                            <option value="15">15 data / page</option>
                                                            <option value="20">20 data / page</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="table-responsive rounded mb-3">
                                                        {loading ? (
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <Preloader />
                                                            </div>
                                                        ) : (
                                                            <table className="data-table table mb-0 tbl-server-info">
                                                                <thead className="bg-white m-0 text-center">
                                                                    <tr className="ligth ligth-data">
                                                                        <th>Customer Number</th>
                                                                        <th>Order Date</th>
                                                                        <th>Total Amount</th>
                                                                        <th>Order Details</th>
                                                                        <th>Order ID</th>
                                                                        <th>Delivery Status</th>
                                                                        <th>Mark as Delivered</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ligth-body m-0 text-center">
                                                                    {currentOrderDetails.length > 0 ? (
                                                                        currentOrderDetails.map(order => (
                                                                            <tr key={order["Order ID"]}>
                                                                                <td> {order["Customer Number"]}</td>
                                                                                <td>{formatDate(order["Order Date"])}</td>
                                                                                <td>₹ {order["Total Amount"]}</td>
                                                                                <td><a className='font-weight-bold badge badge-orange' title='View Order Details'
                                                                                    href={`/orderoverview?order_id=${encodeURIComponent(order["Order ID"])}`}>
                                                                                    View More
                                                                                </a></td>
                                                                                <td> {order["Order ID"]}</td>
                                                                                <td><a className='font-weight-bold badge bg-pink'>
                                                                                    {order["Delivery Status"]}
                                                                                </a></td>
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="badge bg-teal"
                                                                                        data-toggle="modal"
                                                                                        data-target="#deliveryModal"
                                                                                        title="Mark as Delivered"
                                                                                        onClick={() => openModal(order)}
                                                                                    >
                                                                                        Delivered
                                                                                    </button>
                                                                                    <div className="modal" id="deliveryModal" tabIndex="-1" role="dialog">
                                                                                        <div className="modal-dialog" role="document">
                                                                                            <div className="modal-content">
                                                                                                <div className="modal-header">
                                                                                                    <h5 className="modal-title">Mark Order as Delivered</h5>
                                                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                                        <span aria-hidden="true">&times;</span>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="modal-body">
                                                                                                    <form onSubmit={handleSubmit}>
                                                                                                        <div className="form-group">
                                                                                                            <label htmlFor="mobile">Customer Number</label>
                                                                                                            <input type="text" id="mobile" name="mobile" className="form-control"
                                                                                                                value={modalData.customerMobile} readOnly />
                                                                                                        </div>
                                                                                                        <div className="form-group">
                                                                                                            <label htmlFor="order_id">Order ID</label>
                                                                                                            <input type="text" id="order_id" name="order_id" className="form-control"
                                                                                                                value={modalData.orderId} readOnly />
                                                                                                        </div>
                                                                                                        <div className="form-group">
                                                                                                            <label htmlFor="deliveryDate">Delivery Date</label>
                                                                                                            <input type="date" id="deliveryDate" name="deliveryDate" className="form-control"
                                                                                                                value={modalData.deliveryDate} onChange={(e) => setModalData({ ...modalData, deliveryDate: e.target.value })} />
                                                                                                        </div>
                                                                                                        <div className="form-group">
                                                                                                            <label htmlFor="status">Status</label>
                                                                                                            <input type="text" id="status" name="status" className="form-control"
                                                                                                                value={modalData.status} readOnly />
                                                                                                        </div>
                                                                                                        <div className="modal-footer">
                                                                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                                                                                            <button type="submit" className="btn btn-primary">Confirm</button>
                                                                                                        </div>
                                                                                                    </form>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="8">
                                                                                <h5 className='text-pink mt-3'>No Orders Found<i className='fas fa-box ml-5'></i></h5>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        )}
                                                    </div>

                                                    <div className="d-flex flex-wrap justify-content-end mx-5">
                                                        <div className="p-3">
                                                            <button className="badge badge-secondary mr-1" disabled={currentPageOrder === 1} onClick={() => handlePageChangeOrder(currentPageOrder - 1)}>
                                                                <i className="fas fa-arrow-left"></i>
                                                            </button>
                                                            <button className="badge badge-secondary ml-1" disabled={currentPageOrder === totalPagesOrder} onClick={() => handlePageChangeOrder(currentPageOrder + 1)}>
                                                                <i className="fas fa-arrow-right"></i>
                                                            </button>
                                                        </div>
                                                        <div className="p-3">
                                                            Page {currentPageOrder} of {totalPagesOrder}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {activeTab === 'deliver' && (
                                            <div className="tab-pane fade active show" id="pills-deliver-fill" role="tabpanel" aria-labelledby="pills-deliver-tab-fill">
                                                <div className="row p-3 mt-2">
                                                    <div className="col-md-8 input-group mb-2">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="ri-search-line"></i></span>
                                                        </div>
                                                        <input type="text" className="form-control" placeholder="Search by Customer Number / Delivery Date"
                                                            name="delivery" value={searchDelivery} onChange={handleSearchDeliveryChange} />
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <select id="itemsPerPage" className="custom-select" value={itemsPerPageDelivery} onChange={(e) => setItemsPerPageDelivery(parseInt(e.target.value))}>
                                                            <option value="5">05 data / page</option>
                                                            <option value="10">10 data / page</option>
                                                            <option value="15">15 data / page</option>
                                                            <option value="20">20 data / page</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="table-responsive rounded mb-3">
                                                        {loading ? (
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <Preloader />
                                                            </div>
                                                        ) : (
                                                            <table className="data-table table mb-0 tbl-server-info">
                                                                <thead className="bg-white m-0 text-center">
                                                                    <tr className="light">
                                                                        <th>Customer Number</th>
                                                                        <th>Order Date</th>
                                                                        <th>Details</th>
                                                                        <th>Order ID</th>
                                                                        <th>Total Amount</th>
                                                                        <th>Status</th>
                                                                        <th>Delivery Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ligth-body m-0 text-center">
                                                                    {currentDeliveryDetails.length > 0 ? (
                                                                        currentDeliveryDetails.map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td>{item["Customer Number"]}</td>
                                                                                <td>{formatDate(item["Order Date"])}</td>
                                                                                <td><a className='font-weight-bold badge badge-orange' title='View Order Details'
                                                                                    href={`/deliveryoverview?order_id=${encodeURIComponent(item["Order ID"])}`}>
                                                                                    View More
                                                                                </a></td>
                                                                                <td>{item["Order ID"]}</td>
                                                                                <td>₹ {item["Total Amount"]}</td>
                                                                                <td><a className='font-weight-bold badge bg-teal'>
                                                                                    {item["Delivery Status"]}
                                                                                </a></td>
                                                                                <td>{formatDate(item["Delivery Date"])}</td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan={6} className="text-center">
                                                                                <h5 className='text-pink mt-3'>No delivery details available<i className='fas fa-box ml-5'></i></h5>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        )}
                                                    </div>

                                                    <div className="d-flex flex-wrap justify-content-end mx-5">
                                                        <div className="p-3">
                                                            <button className="badge badge-secondary mr-1" disabled={currentPageDelivery === 1} onClick={() => handlePageChangeDelivery(currentPageDelivery - 1)}>
                                                                <i className="fas fa-arrow-left"></i>
                                                            </button>
                                                            <button className="badge badge-secondary ml-1" disabled={currentPageDelivery === totalPagesDelivery} onClick={() => handlePageChangeDelivery(currentPageDelivery + 1)}>
                                                                <i className="fas fa-arrow-right"></i>
                                                            </button>
                                                        </div>
                                                        <div className="p-3">
                                                            Page {currentPageDelivery} of {totalPagesDelivery}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}


                                    </div>
                                    {alert.visible && (
                                        <div className={`alert alert-${alert.type} border-dark`} role="alert">
                                            <div className="iq-alert-icon text-danger">
                                                <i className={`ri-${alert.type === 'success' ? 'check-line' : 'information-line'}`}></i>
                                            </div>
                                            <div className="iq-alert-text text-orange">{alert.message}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ListOrder;