import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {

   const [showPassword, setShowPassword] = useState(false);
   const navigate = useNavigate();

   const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
   };

   const handleSubmit = (e) => {
      e.preventDefault();

      toast.success('Password updated successfully');
      setTimeout(() => {
         navigate('/');
      }, 2000);
   };

   return (
      <div>
         <div class="container">
            <div class="row align-items-center justify-content-center height-self-center">
               <div class="col-lg-5">
                  <div class="card auth-card">
                     <div className="card-body p-0">
                        <div className="col-lg-6 text-center mx-auto mt-3">
                           <img src="../assets/images/page-img/green-mark-logo.png" className="logo" alt="Logo" />
                        </div>
                        {/* <div class="card-body p-0">
                        <div class="d-flex align-items-center auth-content">
                           <div class="col-lg-12 align-self-center">
                              <div class="p-5 text-center mx-auto">
                                 <h3 class="mb-2 text-primary">Reset Password</h3>
                                 <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
                                 <form>
                                    <div class="row">
                                       <div class="col-lg-8 text-center mx-auto">
                                          <div class="floating-label form-group">
                                             <input class="floating-input form-control" type="email" placeholder=" " required />
                                             <label>Email</label>
                                          </div>
                                       </div>
                                    </div>
                                    <button type="submit" formAction='/successmail' class="btn btn-secondary">Reset</button>
                                 </form>
                              </div>
                           </div>
                        </div>
                        </div> */}
                        <div className="d-flex align-items-center auth-content">
                           <div className="col-lg-12 align-self-center">
                              <div className="p-3">
                                 <div className='text-center mx-auto'>
                                    <h2 className="my-4 text-primary">Forgot Password</h2>
                                 </div>
                                 <form onSubmit={handleSubmit}>
                                    <div className="row">
                                       <div className="col-lg-12">
                                          <div className="floating-label form-group">
                                             <input
                                                className="floating-input form-control"
                                                type="text"
                                                placeholder=""
                                             />
                                             <label>Username</label>
                                          </div>
                                       </div>
                                       <div className="col-lg-12">
                                          <div className="floating-label form-group position-relative">
                                             <input
                                                className="floating-input form-control pr-4"
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder=""
                                             />
                                             <span className="show-pass eye position-absolute" onClick={togglePasswordVisibility}>
                                                {showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                             </span>
                                             <label>Create New Password</label>
                                          </div>
                                       </div>
                                       <div className="col-lg-12">
                                          <div className="floating-label form-group position-relative">
                                             <input
                                                className="floating-input form-control pr-4"
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder=""
                                             />
                                             <span className="show-pass eye position-absolute" onClick={togglePasswordVisibility}>
                                                {showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                             </span>
                                             <label>Confirm Password</label>
                                          </div>
                                       </div>
                                    </div>
                                    <div className='text-center mx-auto my-2'>
                                       <button type="submit" className="btn btn-block btn-primary">Submit</button>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default ForgotPassword;