import React from 'react';

function Footer() {
  return (
    <div>
      <footer class="iq-footer">
        <div class="container-fluid">
          <div class="card  text-center mx-auto pt-3">
            <p className='text-dark'>Copyright <b className='text-pink'>©</b> Designed &amp; Developed by <a className='text-teal' href="https://datamoo.ai/" target="blank">DataMoo AI /</a> <a className='text-orange' href="https://www.infonetcomm.com/" target="blank">InfoNet Comm</a> @ 2024</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;