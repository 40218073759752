import React, { useState } from 'react';
import Footer from '../../components/layouts/Footer';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import { apiUrl } from '../../components/basics/BaseUrl';

function AddCategories() {
  const [preview, setPreview] = useState('');
  const [imageName, setImageName] = useState('');
  const [alert, setAlert] = useState({ type: '', message: '', visible: false });
  const [formData, setFormData] = useState({
    category_id: '',
    category_name: '',
    category_image: '',
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'category_image' && files.length > 0) {
      const file = files[0];
      setPreview(URL.createObjectURL(file));
      const nameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
      setImageName(nameWithoutExtension);

      // Convert image to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          category_image: reader.result, // base64 encoded image
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const showAlert = (type, message) => {
    setAlert({ type, message, visible: true });
    setTimeout(() => setAlert({ ...alert, visible: false }), 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      category_id: formData.category_id,
      category_name: formData.category_name,
      category_image: formData.category_image, // base64 image
    };

    console.log('Data to be sent:', data);

    try {
      const response = await fetch(`${apiUrl}/categories/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        showAlert('success', 'Category added successfully!');
        setFormData({
          category_id: '',
          category_name: '',
          category_image: '',
        });
        setPreview('');
      } else {
        const errorData = await response.json();
        showAlert('danger', `Failed to add category: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error adding category:', error);
      showAlert('danger', 'An error occurred while adding the category.');
    }
  };

  return (
    <>
      <div id='wrapper'>
        <div class="iq-sidebar sidebar-default ">
          <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
            <a class="header-logo m-auto p-auto">
              <h5 class="logo-title light-logo text-teal ">Green</h5>
              <h5 class="logo-title light-logo text-orange">-Mark</h5>
            </a>
            <div class="iq-menu-bt-sidebar">
              <i class="las la-bars wrapper-menu"></i>
            </div>
          </div>
          <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <nav class="iq-sidebar-menu ">
              <ul id="iq-sidebar-toggle" class="iq-menu">
                <li class="">
                  <a href="/dashboard" class="svg-icon">
                    <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    <span class="ml-4">Dashboards</span>
                  </a>
                </li>
                <li class="active">
                  <a href="#category" class="collapsed" data-toggle="collapse" aria-expanded="true">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    <span class="ml-4">Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="category" class="iq-submenu collapse show " data-parent="#iq-sidebar-toggle">
                    <li class="active">
                      <a href="/addcategory">
                        <i class="las la-minus"></i><span>Add Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listcategory">
                        <i class="las la-minus"></i><span>List of Category</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg className="svg-icon" id="p-dash-sub" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                      <line x1="7" y1="7" x2="17" y2="7"></line>
                      <line x1="7" y1="12" x2="17" y2="12"></line>
                      <line x1="7" y1="17" x2="17" y2="17"></line>
                    </svg>
                    <span class="ml-4">Sub-Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addsubcategory">
                        <i class="las la-minus"></i><span>Add Sub-Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listsubcategory">
                        <i class="las la-minus"></i><span>Sub-Category List</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg className="svg-icon" id="p-dash-product" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M3 9L12 2l9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                    <span class="ml-4">Products</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="product" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addproduct">
                        <i class="las la-minus"></i><span>Add Product</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listproduct">
                        <i class="las la-minus"></i><span>List of Products</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="/listorder" class="svg-icon">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span class="ml-4">Orders</span>
                  </a>
                </li>
                <li class="">
                  <a href="/customer" className="svg-icon">
                    <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span class="ml-4">Customers</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <NavBar />
        <Header />
        <div className="content-page">
          <div className="container-fluid add-form-list">
            <div className="row mt-4">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <div className="header-title d-flex flex-wrap justify-content-between align-items-center">
                      <h4 className="card-title text-skyblue my-2">Add Category</h4>
                      <a className="btn bg-skyblue m-0 add-list my-2" href="/listcategory">
                        <i className="las la-eye mr-3"></i>View Categories
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className='col-md-8'>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Category Image *</label>
                              <input type="file" className="form-control image-file" name="category_image"
                                accept="image/*" onChange={handleChange} required />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Category Code *</label>
                              <input type="text" className="form-control" placeholder="Enter Category Code"
                                name="category_id" value={formData.category_id} onChange={handleChange} required />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Category Name *</label>
                              <input type="text" className="form-control" placeholder="Enter Category Name"
                                name="category_name" value={formData.category_name} onChange={handleChange} required />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          {preview && (
                            <div className="image-preview carousel-inner m-0 text-center">
                              <img src={preview} alt="Category Preview" className="img-fluid cat-img-prev my-3" />
                              <div className="carousel-caption">
                                <h5 className="text-white text-outline">{imageName.toUpperCase()}</h5>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <button type="submit" className="btn bg-skyblue mt-3 mx-3">Add category</button>
                      <button type="reset" className="btn bg-pink mt-3"
                        onClick={() => {
                          setFormData({
                            category_id: '',
                            category_name: '',
                            category_image: '',
                          });
                          setPreview('');
                        }}
                      >Reset</button>
                    </form>
                  </div>
                  {alert.visible && (
                    <div className={`alert alert-${alert.type} border-dark`} role="alert">
                      <div className="iq-alert-icon text-danger">
                        <i className={`ri-${alert.type === 'success' ? 'check-line' : 'information-line'}`}></i>
                      </div>
                      <div className="iq-alert-text text-orange">{alert.message}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddCategories;
