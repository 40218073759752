import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function SignIn() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();

    const [validCredentials] = useState([
        { username: 'Greenmark', password: 'Admin#24' },
        { username: 'Infonet', password: 'Admin#24' },
        { username: 'Developer', password: 'Dev#24' }
    ]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedPassword = localStorage.getItem('password');
        const storedRememberMe = localStorage.getItem('rememberMe') === 'true';

        if (storedRememberMe) {
            setUsername(storedUsername || '');
            setPassword(storedPassword || '');
            setRememberMe(true);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValidUser = validCredentials.some(
            (cred) => cred.username === username && cred.password === password
        );

        if (isValidUser) {
            if (rememberMe) {
                localStorage.setItem('username', username);
                localStorage.setItem('password', password);
                localStorage.setItem('rememberMe', 'true');
            } else {
                localStorage.removeItem('username');
                localStorage.removeItem('password');
                localStorage.removeItem('rememberMe');
            }

            toast.success('Signed in successfully');
            setTimeout(() => {
                navigate('/dashboard');
            }, 2000);
        } else {
            toast.error('Invalid username or password');
        }
    };

    const handleRememberMeChange = (e) => {
        setRememberMe(e.target.checked);
    };

    return (
        <div>
            <div className="container">
                <div className="row align-items-center justify-content-center height-self-center">
                    <div className="col-lg-5">
                        <div className="card auth-card">
                            <div className="card-body p-0">
                                <div className="col-lg-6 text-center mx-auto mt-3">
                                    <img src="../assets/images/page-img/green-mark-logo.png" className="logo" alt="Logo" />
                                </div>
                                <div className="d-flex align-items-center auth-content">
                                    <div className="col-lg-12 align-self-center">
                                        <div className="p-3">
                                            <div className='text-center mx-auto'>
                                                <h2 className="my-4 text-primary">Sign In</h2>
                                            </div>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="floating-label form-group">
                                                            <input 
                                                                className="floating-input form-control" 
                                                                type="text" 
                                                                value={username} 
                                                                onChange={handleUsernameChange} 
                                                                placeholder="" required
                                                            />
                                                            <label>Username</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="floating-label form-group position-relative">
                                                            <input 
                                                                className="floating-input form-control pr-4" 
                                                                type={showPassword ? 'text' : 'password'} 
                                                                value={password} 
                                                                onChange={handlePasswordChange} 
                                                                placeholder="" required
                                                            />
                                                            <span className="show-pass eye position-absolute" onClick={togglePasswordVisibility}>
                                                                {showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                                                            </span>
                                                            <label>Password</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input 
                                                                type="checkbox" 
                                                                className="custom-control-input" 
                                                                id="customCheck1" 
                                                                checked={rememberMe} 
                                                                onChange={handleRememberMeChange} 
                                                            />
                                                            <label className="custom-control-label control-label-1" htmlFor="customCheck1">Remember Me</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-2">
                                                        {/* <a href="/forgotpassword" className="text-pink float-right">Forgot Password?</a> */}
                                                    </div>
                                                </div>
                                                <div className='text-center mx-auto my-2'>
                                                    <button type="submit" className="btn btn-block btn-primary">Sign In</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default SignIn;
