import React, { useState, useEffect } from 'react';
import Header from '../../components/layouts/Header';
import NavBar from '../../components/layouts/NavBar';
import Footer from '../../components/layouts/Footer';
import { apiUrl } from '../../components/basics/BaseUrl';
import { useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';

function CartOverview() {
    const [cartDetails, setCartDetails] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [customerId, setCustomerId] = useState();
    const [customerMobile, setCustomerMobile] = useState();
    const [addedCartDate, setAddedCartDate] = useState();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mobileNumber = queryParams.get('mobile');

    useEffect(() => {
        if (mobileNumber) {

            fetch(`${apiUrl}/getcartdetails/?mobile=${mobileNumber}`)
                .then(response => response.json())
                .then(data => {
                    const details = data.cartdetails;
                    setCartDetails(details);
                    setTotalAmount(data['Total Amount']);

                    if (details.length > 0) {
                        setCustomerId(details[0].customer_id);
                        setCustomerMobile(details[0].customer_mobile);
                        setAddedCartDate(formatDate(details[0].created_at));
                    }
                })
                .catch(error => console.error('Error fetching cart details:', error));
        }
    }, [mobileNumber]);

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours();
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHour = hours % 12 || 12;

        return `${day} / ${month} / ${year} - ${formattedHour}:${minutes} ${ampm}`;
    };

    const exportToExcel = () => {
        const cartData = cartDetails.map((item) => ({
            'Product Name': item.product_name,
            'Price': item.price,
            'Quantity': item.quantity,
            'Total Amount': item.totalamt,
        }));

        const data = [
            {
                'Customer ID': customerId,
                'Customer Mobile': customerMobile,
                'Added to Cart': addedCartDate,
                'Total Amount': totalAmount,
            },
            ...cartData,
        ];

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Cart Details');

        XLSX.writeFile(wb, `Cart_Details_${customerMobile}.xlsx`);
    };

    return (
        <>
            <div id="wrapper">
                <div className="iq-sidebar sidebar-default">
                    <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
                        <a className="header-logo m-auto p-auto">
                            <h5 className="logo-title light-logo text-teal ">Green</h5>
                            <h5 className="logo-title light-logo text-orange">-Mark</h5>
                        </a>
                        <div className="iq-menu-bt-sidebar">
                            <i className="las la-bars wrapper-menu"></i>
                        </div>
                    </div>
                    <div className="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        <nav className="iq-sidebar-menu ">
                            <ul id="iq-sidebar-toggle" className="iq-menu">
                                <li className="">
                                    <a href="/dashboard" className="svg-icon">
                                        <svg className="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                        </svg>
                                        <span className="ml-4">Dashboards</span>
                                    </a>
                                </li>
                                <li className="">
                                    <a href="#category" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                        <span className="ml-4">Categories</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="category" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/addcategory">
                                                <i className="las la-minus"></i><span>Add Category</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/listcategory">
                                                <i className="las la-minus"></i><span>List of Category</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash-sub" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="7" y1="7" x2="17" y2="7"></line>
                                            <line x1="7" y1="12" x2="17" y2="12"></line>
                                            <line x1="7" y1="17" x2="17" y2="17"></line>
                                        </svg>
                                        <span class="ml-4">Sub-Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addsubcategory">
                                                <i class="las la-minus"></i><span>Add Sub-Category</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listsubcategory">
                                                <i class="las la-minus"></i><span>Sub-Category List</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="">
                                    <a href="#product" className="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash-product" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M3 9L12 2l9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9z"></path>
                                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                        </svg>
                                        <span className="ml-4">Products</span>
                                        <svg className="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="product" className="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li className="">
                                            <a href="/addproduct">
                                                <i className="las la-minus"></i><span>Add Product</span>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="/listproduct">
                                                <i className="las la-minus"></i><span>List of Products</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="active">
                                    <a href="/listorder" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="9" cy="21" r="1"></circle>
                                            <circle cx="20" cy="21" r="1"></circle>
                                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                        </svg>
                                        <span class="ml-4">Orders</span>
                                    </a>
                                </li>
                                <li className="">
                                    <a href="/customer" className="svg-icon">
                                        <svg className="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="9" cy="7" r="4"></circle>
                                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                        </svg>
                                        <span className="ml-4">Customers</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <NavBar />
                <Header />
                <div className="content-page">
                    <div className="container-fluid add-form-list">
                        <div className="row">
                            <div className="col-lg-12 my-4">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <h4 className="m-0 p-0 text-skyblue my-2">Cart Overview</h4>
                                    <a className="btn bg-skyblue m-0 add-list" href="/listorder">
                                        <i className="fas fa-chevron-left mr-3"></i>Back
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <h6 className="mb-1"># {customerId}</h6>
                                        <p className="text-uppercase text-dark"><b>User ID</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <h6 className="mb-1">+91 {customerMobile}</h6>
                                        <p className="text-uppercase text-dark"><b>User Number</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <h6 className="mb-1">{addedCartDate}</h6>
                                        <p className="text-uppercase text-dark"><b>Added to Cart</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl'>
                                <div className="card">
                                    <div className="text-center card-body">
                                        <h6 className="mb-1">₹ {totalAmount}</h6>
                                        <p className="text-uppercase text-dark"><b>Total Amount</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className='card-header d-flex align-items-center justify-content-between'>
                                <h4 className="text-orange m-0">Cart Details</h4>
                                <button className="btn bg-teal m-0 add-list" onClick={exportToExcel}>
                                    <i className="fas fa-download mr-3"></i>Export .xlsx
                                </button>
                            </div>
                            <div className="card-body">
                                {cartDetails.length > 0 ? (
                                    cartDetails.map((item, index) => (
                                        <div key={index}>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-flex'>
                                                    <img src={`data:image/png;base64,${item.product_image}`}
                                                        alt='product' className='img-fluid rounded avatar-50 mr-3' />
                                                    <span>
                                                        <h6 className='text-dark'>{item.product_name}</h6>
                                                        <h6>₹ {item.price} x {item.quantity}</h6>
                                                    </span>
                                                </div>
                                                <h6>₹ {item.totalamt}</h6>
                                            </div>
                                            <hr />
                                        </div>
                                    ))
                                ) : (
                                    <h5 className='text-center text-danger'>No items in the cart.<hr /></h5>

                                )}
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6>Sub-Total</h6>
                                    <h6>₹ {totalAmount}</h6>
                                </div>
                                <hr />
                                {/* <div className='d-flex align-items-center justify-content-between'>
                                    <h6>Estimated Tax <span className='text-dark'>(0%)</span></h6>
                                    <h6>₹ 0</h6>
                                </div>
                                <hr />
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6>Item Discounts <span className='text-dark'>(0%)</span></h6>
                                    <h6>₹ 0</h6>
                                </div>
                                <hr /> */}
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6>Delivery Charge</h6>
                                    <h6>₹ 0</h6>
                                </div>
                                <hr />
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6>Total Amount</h6>
                                    <h6>₹ {totalAmount}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default CartOverview;
