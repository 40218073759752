import React, { useEffect, useState } from "react";
import { apiUrl } from "../basics/BaseUrl";
import { useNavigate } from 'react-router-dom';

function NavBar() {

  const [cartItems, setCartItems] = useState([]);
  const [sortedCartData, setSortedCartData] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [sortedOrderData, setSortedOrderData] = useState([]);

  const navigate = useNavigate();
  const [categories, setCategories] = useState([
    {
      id: "1", category_image: "../assets/images/category-img/dairy.png",
      category_name: 'Dairy Products'
    },
    {
      id: "2", category_image: "../assets/images/category-img/cereals.png",
      category_name: 'Cereals'
    },
    {
      id: "3", category_image: "../assets/images/category-img/vegetables.png",
      category_name: 'Vegetables'
    },
    {
      id: "4", category_image: "../assets/images/category-img/fruits.png",
      category_name: 'Fruits'
    },
  ]);

  const GoListCategory = () => {
    navigate('/listcategory');
  };

  useEffect(() => {
    fetchOrderDetails();
    fetchCartDetails();
  }, []);

  const fetchCartDetails = async () => {
    try {
      const response = await fetch(`${apiUrl}/getcartdetails/`, {
        headers: { 'accept': 'application/json' }
      });
      const data = await response.json();

      const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setSortedCartData(sortedData);
      setCartItems(sortedCartData.slice(0, 3));
    } catch (error) {
      console.error('Error fetching cart details:', error);
    }
  };

  const fetchOrderDetails = async () => {
    try {
      const response = await fetch(`${apiUrl}/get_order_summary`, {
        headers: {
          accept: 'application/json',
        },
      });
      const data = await response.json();

      if (data.length > 0) {
        const sortedData = data.sort((a, b) => new Date(b["Order Date"]) - new Date(a["Order Date"]));
        setSortedOrderData(sortedData);
        setOrderItems(sortedOrderData.slice(0, 3))
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  return (
    <div>
      <div className="iq-top-navbar py-0 my-0">
        <div className="iq-navbar-custom py-0 my-0">
          <nav className="navbar navbar-expand-lg navbar-light py-0 my-0">
            <div className="iq-search-bar device-search">
            </div>
            <div className="d-flex align-items-center">
              <i className="las la-bars wrapper-menu"></i>
              <div className="" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto navbar-list align-items-center">
                  <li className="nav-item nav-icon dropdown">
                    <a href="#" onClick={fetchCartDetails} className="search-toggle dropdown-toggle" id="dropdownMenuCart"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Cart Details">
                      <i className="fas fa-shopping-cart text-teal"></i>
                      <span className="badge bg-orange badge-icon">{sortedCartData.length}</span>
                    </a>
                    <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuCart">
                      <div className="card shadow-none m-0">
                        <div className="card-body p-0">
                          <div className="cust-title p-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0 text-orange">Added to Cart</h5>
                              <a className="badge bg-pink badge-card" href="#">{sortedCartData.length}</a>
                            </div>
                          </div>
                          {cartItems.length > 0 ? (
                            cartItems.map((item, index) => (
                              <div key={index} className="px-3 pt-0 pb-0 sub-card">
                                <div className="iq-sub-card">
                                  <div className="media align-items-center cust-card py-2 border-bottom line-height-150">
                                    <div className="media-body">
                                      <small>
                                        Mobile: <b>{item.mobile}</b>
                                      </small>
                                      <div className=" d-flex align-items-center justify-content-between">
                                        <small>
                                          <span>Date: <b>{formatDate(item.date)}</b></span>
                                        </small>
                                        <div className="">
                                          <small>
                                            Total Amount: <b>₹ {item.amount}</b>
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="px-3 pt-0 pb-0 sub-card">
                              <p className="text-center">No items in cart.</p>
                            </div>
                          )}
                          <a className="right-ic btn bg-teal btn-block position-relative p-2" href="/listorder" role="button" > View All </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item nav-icon dropdown mx-5">
                    <a href="#" onClick={fetchOrderDetails} className="search-toggle dropdown-toggle" id="dropdownMenuOrder"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Order Details">
                      <i className="fas fa-bar-chart text-teal"></i>
                      <span className="badge bg-orange badge-icon">{sortedOrderData.length}</span>
                    </a>
                    <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuOrder">
                      <div className="card shadow-none m-0">
                        <div className="card-body p-0">
                          <div className="cust-title p-3">
                            <div className="d-flex align-items-center justify-content-between">
                              <h5 className="mb-0 text-orange">Orders Placed</h5>
                              <a className="badge bg-pink badge-card" href="#">{sortedOrderData.length}</a>
                            </div>
                          </div>
                          {orderItems.length > 0 ? (
                            orderItems.map((orderItems, index) => (
                              <div key={index} className="px-3 pt-0 pb-0 sub-card">
                                <div className="iq-sub-card">
                                  <div className="media align-items-center cust-card py-2 border-bottom line-height-150">
                                    <div className="media-body">
                                      <small>
                                        Mobile: <b>{orderItems["Customer Number"]}</b>
                                      </small>
                                      <div className=" d-flex align-items-center justify-content-between">
                                        <small>
                                          <span>Date: <b>{formatDate(orderItems["Order Date"])}</b></span>
                                        </small>
                                        <div className="">
                                          <small>
                                            Total Amount: <b>₹ {orderItems["Total Amount"]}</b>
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="px-3 pt-0 pb-0 sub-card">
                              <p className="text-center">No Active Orders.</p>
                            </div>
                          )}
                          <a className="right-ic btn bg-teal btn-block position-relative p-2" href="/listorder" role="button"> View All </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item nav-icon dropdown caption-content">
                    <a href="#" className="search-toggle dropdown-toggle" id="dropdownMenuButton4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {/* <img src="../assets/images/green-mark-logo.png" className="img-fluid rounded" alt="user" /> */}
                      <i className="fas fa-user border border-success text-orange rounded p-3"></i>
                    </a>
                    <div className="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <div className="card shadow-none m-0">
                        <div className="card-body p-0 text-center">
                          <div className="media-body profile-detail text-center">
                            <img src="../assets/images/green-mark-logo.png" alt="profile-bg" className="rounded-top img-fluid avatar-100" />
                          </div>
                          <hr className="mt-0 pt-0"/>
                          <div className="mb-3">
                            <h5 className="mb-3 text-teal text-uppercase">Admin</h5>
                            <a href="/" className="btn bg-orange border">Sign Out</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
