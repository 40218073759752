import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';
import { apiUrl } from '../../components/basics/BaseUrl';

function Invoice() {
  const [orderDetails, setOrderDetails] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [orderStatus, setOrderStatus] = useState('');
  const [orderDate, setOrderDate] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('order_id');
  const printableRef = useRef(null); // Reference to the printable content

  useEffect(() => {
    if (orderId) {
      fetch(`${apiUrl}/get_order_details?order_id=${orderId}`, {
        headers: {
          accept: 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          const details = data.order_details;
          setOrderDetails(details);
          setCustomerData({
            totalAmount: data.total_amount,
            address: data.address,
            state: data.state,
            city: data.city,
            pincode: data.pincode,
            email: data.email,
            name: data.name,
            mobile: data.mobile,
          });

          if (details.length > 0) {
            setOrderDate(formatDate(details[0].created_at));
            setOrderStatus(details[0].status);
          }
        })
        .catch(error => console.error('Error fetching order details:', error));
    }
  }, [orderId]);

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHour = hours % 12 || 12;

    return `${day} / ${month} / ${year} - ${formattedHour}:${minutes} ${ampm}`;
  };

  // Export to PDF function
  const exportToPDF = () => {
    const content = printableRef.current; // Get the printable area
    const opt = {
      margin: 0.5,
      filename: `Invoice-${orderId}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    html2pdf().from(content).set(opt).save();
  };

  return (
    <>
      <div id='wrapper'>
        <div className="iq-sidebar sidebar-default ">
          {/* Sidebar Content */}
        </div>
        <NavBar />
        <Header />
        <div className="content-page" ref={printableRef}> {/* Printable area reference */}
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card card-block card-stretch card-height print rounded">
                  <div className="card-header d-flex justify-content-between bg-primary header-invoice">
                    <div className="iq-header-title">
                      <h3 className="card-title mb-0">Invoice - {orderId}</h3>
                    </div>
                    <div className="invoice-btn">
                      <button type="button" className="btn bg-orange" onClick={exportToPDF}>
                        <i className="las la-file-download"></i> PDF
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row mb-4 text-dark">
                      <div className="col-lg-6">
                        <hr />
                        <h4 className='text-skyblue'>Billing Information</h4>
                        <hr />
                        <p>
                          <b>Name:</b> {customerData.name}<br />
                          <b>Email:</b> {customerData.email || 'N/A'}<br />
                          <b>Address:</b> {customerData.address}, {customerData.city}, {customerData.state}, {customerData.pincode}.
                        </p>
                      </div>
                      <div className="col-lg-6 text-right">
                        <hr />
                        <h4 className='text-skyblue'>Order Summary</h4>
                        <hr />
                        <p>
                          <b>Order Date : </b> {orderDate || 'N/A'}<br />
                          <b>Order ID : </b> {orderId || 'N/A'}<br />
                          <b>Phone:</b> {customerData.mobile}<br />
                          <b>Delivery Status : </b> <span className='badge bg-pink'> {orderStatus || 'N/A'}</span>
                        </p>
                      </div>
                    </div>

                    <div className="table-responsive-sm mb-4">
                      <table className="table table-bordered">
                        <thead>
                          <tr className='text-primary font-size-20'>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Sub-Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderDetails.map((order, index) => (
                            <tr key={index}>
                              <td className="d-flex align-items-center">
                                {order.product_image && (
                                  <img src={`data:image/jpeg;base64,${order.product_image}`}
                                    alt='product' className='img-fluid rounded avatar-50 mr-3' />
                                )}
                                {order.product_name}
                              </td>
                              <td>{order.quantity}</td>
                              <td>₹ {order.price}</td>
                              <td>₹ {order.price * order.quantity}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="card">
                      <div className="card-body">
                        <div className='d-flex align-items-center justify-content-between'>
                          <h6 className='text-skyblue'>Sub Total</h6>
                          <h6 className='text-skyblue'>₹ {orderDetails.reduce((acc, order) => acc + (order.price * order.quantity), 0)}</h6>
                        </div>
                        <hr />
                        <div className='d-flex align-items-center justify-content-between'>
                          <h6 className='text-skyblue'>Delivery Charges</h6>
                          <h6 className='text-skyblue'>₹ 0</h6>
                        </div>
                        <hr />
                        <div className='d-flex align-items-center justify-content-between'>
                          <h6 className='text-orange'><b>Total Amount</b></h6>
                          <h6 className='text-orange'><b>₹ {orderDetails.reduce((acc, order) => acc + (order.price * order.quantity), 0)}</b></h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Invoice;
