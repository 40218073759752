import React, { useState, useEffect } from 'react';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';
import { apiUrl } from '../../components/basics/BaseUrl';
import Preloader from '../../components/layouts/Footer';

function Customer() {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);

  const [alert, setAlert] = useState({ type: '', message: '', visible: false });

  const showAlert = (type, message) => {
    setAlert({ type, message, visible: true });
    setTimeout(() => setAlert({ ...alert, visible: false }), 3000);
  };

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/getcustomer`, {
        method: 'GET',
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data)) {
          setCustomers(data);
          setFilteredCustomers(data);
          showAlert('success', 'Customers fetched successfully');
        } else {
          setCustomers([]);
          setFilteredCustomers([]);
          showAlert('info', 'No customer data available');
        }
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      setCustomers([]);
      setFilteredCustomers([]);
      showAlert('danger', 'Error fetching customers');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (Array.isArray(customers)) {
      const results = customers.filter(customer =>
        (customer.customer_name && customer.customer_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (customer.Email && customer.Email.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (customer.Mobile && customer.Mobile.includes(searchTerm))
      );
      setFilteredCustomers(results);
    }
    setCurrentPage(1);
  }, [searchTerm, customers]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const indexOfLastCustomer = currentPage * itemsPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - itemsPerPage;
  const currentCustomers = filteredCustomers?.slice(indexOfFirstCustomer, indexOfLastCustomer) || [];
  const totalPages = Math.ceil(filteredCustomers?.length / itemsPerPage) || 1;

  return (
    <>
      <div id='wrapper'>
        <div class="iq-sidebar sidebar-default ">
          <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
            <a class="header-logo m-auto p-auto">
              <h5 class="logo-title light-logo text-teal ">Green</h5>
              <h5 class="logo-title light-logo text-orange">-Mark</h5>
            </a>
            <div class="iq-menu-bt-sidebar">
              <i class="las la-bars wrapper-menu"></i>
            </div>
          </div>
          <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
            <nav class="iq-sidebar-menu ">
              <ul id="iq-sidebar-toggle" class="iq-menu">
                <li class="">
                  <a href="/dashboard" class="svg-icon">
                    <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    <span class="ml-4">Dashboards</span>
                  </a>
                </li>
                <li class="">
                  <a href="#category" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                    <span class="ml-4">Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="category" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addcategory">
                        <i class="las la-minus"></i><span>Add Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listcategory">
                        <i class="las la-minus"></i><span>List of Category</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg className="svg-icon" id="p-dash-sub" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                      <line x1="7" y1="7" x2="17" y2="7"></line>
                      <line x1="7" y1="12" x2="17" y2="12"></line>
                      <line x1="7" y1="17" x2="17" y2="17"></line>
                    </svg>
                    <span class="ml-4">Sub-Categories</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="subcategory" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addsubcategory">
                        <i class="las la-minus"></i><span>Add Sub-Category</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listsubcategory">
                        <i class="las la-minus"></i><span>Sub-Category List</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                    <svg className="svg-icon" id="p-dash-product" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M3 9L12 2l9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                    <span class="ml-4">Products</span>
                    <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <polyline points="10 15 15 20 20 15"></polyline>
                      <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                    </svg>
                  </a>
                  <ul id="product" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                    <li class="">
                      <a href="/addproduct">
                        <i class="las la-minus"></i><span>Add Product</span>
                      </a>
                    </li>
                    <li class="">
                      <a href="/listproduct">
                        <i class="las la-minus"></i><span>List of Products</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="">
                  <a href="/listorder" class="svg-icon">
                    <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                    <span class="ml-4">Orders</span>
                  </a>
                </li>
                <li class="active">
                  <a href="/customer" className="svg-icon">
                    <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span class="ml-4">Customers</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <NavBar />
        <Header />
        <div className="content-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 my-4">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                  <div>
                    <h4 className="m-0 p-0 text-skyblue my-2">Customer List</h4>
                  </div>
                </div>
                <div className='row p-3 mt-2'>
                  <div className="col-md-8 input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><i className="ri-search-line"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search Customer by Name / Mobile number"
                      value={searchTerm} name='customer' onChange={handleSearch} />
                  </div>
                  <div className='col-md-4'>
                    <select id="itemsPerPage" className="custom-select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                      <option value="5">05 data / page</option>
                      <option value="10">10 data / page</option>
                      <option value="15">15 data / page</option>
                      <option value="20">20 data / page</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="table-responsive rounded mb-3">
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <Preloader />
                    </div>
                  ) : (
                    <table className="data-table table mb-0 tbl-server-info">
                      <thead className="bg-white m-0 text-center">
                        <tr className="ligth ligth-data">
                          <th>Customer Name</th>
                          <th>Mobile Number</th>
                          <th>Email ID</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Pin-code</th>
                          <th>Address</th>
                          <th>Expenses</th>
                        </tr>
                      </thead>
                      <tbody className="ligth-body m-0 text-center">
                        {currentCustomers.length > 0 ? (
                          currentCustomers.map(customer => (
                            <tr key={customer.id}>
                              <td>{customer.customer_name || 'N/A'}</td>
                              <td>{customer.Mobile || 'N/A'}</td>
                              <td>{customer.Email || 'N/A'}</td>
                              <td>{customer.City || 'N/A'}</td>
                              <td>{customer.State || 'N/A'}</td>
                              <td>{customer.Pincode || 'N/A'}</td>
                              <td>{customer.Address || 'N/A'}</td>
                              <td>₹ {customer.Total_amount || 'N/A'}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8"><h5 className='text-center text-pink mt-3'>No customers available <i className='fas fa-user-alt ml-5'></i></h5></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-end mx-5">
            <div className="p-3">
              <button className="badge badge-secondary mr-1" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                <i className="fas fa-arrow-left"></i>
              </button>
              <button className="badge badge-secondary ml-1" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} >
                <i className="fas fa-arrow-right"></i>
              </button>
            </div>
            <div className="p-3">
              Page {currentPage} of {totalPages}
            </div>
          </div>
          {alert.visible && (
            <div className={`alert alert-${alert.type} border-dark`} role="alert">
              <div className="iq-alert-icon text-danger">
                <i className={`ri-${alert.type === 'success' ? 'check-line' : 'information-line'}`}></i>
              </div>
              <div className="iq-alert-text text-orange">{alert.message}</div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Customer;
