import React, { useState, useEffect } from 'react';
import NavBar from '../../components/layouts/NavBar';
import Header from '../../components/layouts/Header';
import Footer from '../../components/layouts/Footer';
import { apiUrl } from '../../components/basics/BaseUrl';
import Preloader from '../../components/layouts/Footer';

function ListSubCategory() {
    const [listSubCategory, setListSubCategory] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [subcategoryToEdit, setsubcategoryToEdit] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [subcategoryToDelete, setsubcategoryToDelete] = useState(null);
    const [alert, setAlert] = useState({ type: '', message: '', visible: false });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    const showAlert = (type, message) => {
        setAlert({ type, message, visible: true });
        setTimeout(() => setAlert({ ...alert, visible: false }), 2000);
    };

    const fetchSubCategories = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/getsubcategories/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });
            if (response.ok) {
                const data = await response.json();
                const sortedData = data.sort((a, b) => a.id - b.id);
                setListSubCategory(sortedData);
                showAlert('success', 'Subcategories loaded successfully');
            } else {
                showAlert('danger', 'Failed to load subcategories');
            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
            showAlert('danger', 'Error fetching subcategories');
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${apiUrl}/categories/`, {
                method: 'GET',
                headers: { Accept: 'application/json' },
            });
            if (response.ok) {
                const data = await response.json();
                setCategories(data);
            }
        } catch (error) {
            console.error('Error fetching categories', error);
        }
    };

    useEffect(() => {
        fetchSubCategories();
        fetchCategories();
    }, []);

    const handleEditClick = (subcategory) => {
        setsubcategoryToEdit(subcategory);
        setEditModalVisible(true);
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${apiUrl}/edit_subcategories/${subcategoryToEdit.id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    category_name: subcategoryToEdit.category_name,
                    sub_category_id: subcategoryToEdit.sub_category_id,
                    sub_category_name: subcategoryToEdit.sub_category_name,
                    sub_category_image: subcategoryToEdit.sub_category_image,
                }),
            });

            if (response.ok) {
                showAlert('success', 'Subcategory updated successfully');
                setEditModalVisible(false);
                fetchSubCategories();
            } else {
                showAlert('danger', 'Failed to update subcategory');
            }
        } catch (error) {
            console.error('Error updating subcategory:', error);
            showAlert('danger', 'Error updating subcategory');
        }
    };

    const handleDeleteClick = (subcategory) => {
        setsubcategoryToDelete(subcategory);
        setDeleteModalVisible(true);
    };

    const handleDeleteSubmit = async () => {
        try {
            const response = await fetch(`${apiUrl}/delete_subcategories/${subcategoryToDelete.sub_category_name}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                },
            });

            if (response.ok) {
                showAlert('success', 'Subcategory deleted successfully');
                setDeleteModalVisible(false);
                fetchSubCategories();
            } else {
                showAlert('danger', 'Failed to delete subcategory');
            }
        } catch (error) {
            console.error('Error deleting subcategory:', error);
            showAlert('danger', 'Error deleting subcategory');
        }
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalVisible(false);
    };

    const handleCloseEditModal = () => {
        setEditModalVisible(false);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const filteredSubCategories = listSubCategory.filter(subcategory =>
        subcategory.sub_category_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        subcategory.category_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastSubCategory = currentPage * itemsPerPage;
    const indexOfFirstSubCategory = indexOfLastSubCategory - itemsPerPage;
    const currentSubCategories = filteredSubCategories.slice(indexOfFirstSubCategory, indexOfLastSubCategory);
    const totalPages = Math.ceil(filteredSubCategories.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    return (
        <>
            <div id='wrapper'>
                <div class="iq-sidebar sidebar-default ">
                    <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
                        <a class="header-logo m-auto p-auto">
                            <h5 class="logo-title light-logo text-teal ">Green</h5>
                            <h5 class="logo-title light-logo text-orange">-Mark</h5>
                        </a>
                        <div class="iq-menu-bt-sidebar">
                            <i class="las la-bars wrapper-menu"></i>
                        </div>
                    </div>
                    <div class="data-scrollbar mt-3" data-scroll="1" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        <nav class="iq-sidebar-menu ">
                            <ul id="iq-sidebar-toggle" class="iq-menu">
                                <li class="">
                                    <a href="/dashboard" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                        </svg>
                                        <span class="ml-4">Dashboards</span>
                                    </a>
                                </li>
                                <li class="">
                                    <a href="#category" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                        </svg>
                                        <span class="ml-4">Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="category" class="iq-submenu collapse " data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addcategory">
                                                <i class="las la-minus"></i><span>Add Category</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listcategory">
                                                <i class="las la-minus"></i><span>List of Category</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="active">
                                    <a href="#subcategory" class="collapsed" data-toggle="collapse" aria-expanded="true">
                                        <svg className="svg-icon" id="p-dash-sub" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="7" y1="7" x2="17" y2="7"></line>
                                            <line x1="7" y1="12" x2="17" y2="12"></line>
                                            <line x1="7" y1="17" x2="17" y2="17"></line>
                                        </svg>
                                        <span class="ml-4">Sub-Categories</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="subcategory" class="iq-submenu collapse show " data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addsubcategory">
                                                <i class="las la-minus"></i><span>Add Sub-Category</span>
                                            </a>
                                        </li>
                                        <li class="active">
                                            <a href="/listsubcategory">
                                                <i class="las la-minus"></i><span>Sub-Category List</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="#product" class="collapsed" data-toggle="collapse" aria-expanded="false">
                                        <svg className="svg-icon" id="p-dash-product" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M3 9L12 2l9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9z"></path>
                                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                        </svg>
                                        <span class="ml-4">Products</span>
                                        <svg class="svg-icon iq-arrow-right arrow-" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <polyline points="10 15 15 20 20 15"></polyline>
                                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                        </svg>
                                    </a>
                                    <ul id="product" class="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                                        <li class="">
                                            <a href="/addproduct">
                                                <i class="las la-minus"></i><span>Add Product</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a href="/listproduct">
                                                <i class="las la-minus"></i><span>List of Products</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="">
                                    <a href="/listorder" class="svg-icon">
                                        <svg class="svg-icon" id="p-dash2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="9" cy="21" r="1"></circle>
                                            <circle cx="20" cy="21" r="1"></circle>
                                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                        </svg>
                                        <span class="ml-4">Orders</span>
                                    </a>
                                </li>
                                <li class="">
                                    <a href="/customer" className="svg-icon">
                                        <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="9" cy="7" r="4"></circle>
                                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                        </svg>
                                        <span class="ml-4">Customers</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <NavBar />
                <Header />
                <div className="content-page">
                    <div className="container-fluid add-form-list">
                        <div className="row">
                            <div className="col-lg-12 my-4">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div> <h4 className="m-0 p-0 text-skyblue my-2">Sub-Category List</h4> </div>
                                    <a className="btn bg-skyblue m-0 add-list my-2" href='/addsubcategory'><i className="las la-plus mr-2"></i>Add Sub-Category</a>
                                </div>
                                <div className='row p-3 mt-2'>
                                    <div className="col-md-8 input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"> <i className="ri-search-line"></i> </span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Search by Category / Sub-Category Name"
                                            name="sub_category_name" value={searchTerm} onChange={handleSearchChange} />
                                    </div>
                                    <div className='col-md-4'>
                                        <select id="itemsPerPage" className="custom-select"
                                            value={itemsPerPage} onChange={handleItemsPerPageChange} >
                                            <option value="5">05 data / page</option>
                                            <option value="10">10 data / page</option>
                                            <option value="15">15 data / page</option>
                                            <option value="20">20 data / page</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="table-responsive rounded mb-3">
                                    {loading ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Preloader />
                                        </div>
                                    ) : (
                                        <table className="data-table table mb-0 tbl-server-info">
                                            <thead className="bg-white m-0 text-center">
                                                <tr className="ligth ligth-data">
                                                    <th>Sub-Category</th>
                                                    <th>Sub-Category Name</th>
                                                    <th>Sub-Category Code</th>
                                                    <th>Category Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="ligth-body m-0 text-center">
                                                {currentSubCategories.length > 0 ? (
                                                    currentSubCategories.map((subcategory) => (
                                                        <tr key={subcategory.id}>
                                                            <td><img src={`data:image/jpeg;base64,${subcategory.sub_category_image}`} alt={subcategory.sub_category_name} className="img-fluid rounded avatar-50" /></td>
                                                            <td>{subcategory.sub_category_name}</td>
                                                            <td>{subcategory.sub_category_id}</td>
                                                            <td>{subcategory.category_name}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center list-action">
                                                                    <a className="badge bg-orange mr-2" onClick={() => handleEditClick(subcategory)} data-toggle="tooltip" data-placement="top" title="Edit">
                                                                        <i className="ri-pencil-line mr-0"></i>
                                                                    </a>
                                                                    <a className="badge bg-pink mr-2" onClick={() => handleDeleteClick(subcategory)} data-toggle="tooltip" data-placement="top" title="Delete">
                                                                        <i className="ri-delete-bin-line mr-0"></i>
                                                                    </a>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr><td colSpan="6"><h5 className='text-pink mt-3'>No sub-categories found<i className='fas fa-shopping-cart ml-5'></i></h5></td></tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {editModalVisible &&
                        <div className="modal" style={{ display: 'block' }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-orange">Edit Sub-Category</h5>
                                        <button type="button" className="close" onClick={handleCloseEditModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={handleEditSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="id">Sub-Category ID</label>
                                                <input type="text" id="id" name="id" className="form-control" disabled
                                                    value={subcategoryToEdit?.id || ''} onChange={(e) => setsubcategoryToEdit({ ...subcategoryToEdit, id: e.target.value })} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="sub_category_name">Sub-Category Name</label>
                                                <input type="text" id="sub_category_name" name="sub_category_name" className="form-control"
                                                    value={subcategoryToEdit?.sub_category_name || ''} onChange={(e) => setsubcategoryToEdit({ ...subcategoryToEdit, sub_category_name: e.target.value })} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="sub_category_code">Sub-Category Code</label>
                                                <input type="text" id="sub_category_code" name="sub_category_id" className="form-control"
                                                    value={subcategoryToEdit?.sub_category_id || ''} onChange={(e) => setsubcategoryToEdit({ ...subcategoryToEdit, sub_category_id: e.target.value })} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="category_name">Category Name</label>
                                                <select id="category_name" name="category_name" className="custom-select" value={subcategoryToEdit?.category_name || ''}
                                                    onChange={(e) => setsubcategoryToEdit({ ...subcategoryToEdit, category_name: e.target.value, })} >
                                                    <option value="" disabled>
                                                        Select Category
                                                    </option>
                                                    {categories.map((category) => (
                                                        <option key={category.id} value={category.category_name}>
                                                            {category.category_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" onClick={handleCloseEditModal}>Cancel</button>
                                                <button type="submit" className="btn bg-teal">Save changes</button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {deleteModalVisible &&
                        <div className="modal" style={{ display: 'block' }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-skyblue">Confirm Deletion</h5>
                                        <button type="button" className="close" onClick={handleCloseDeleteModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p>Are you sure you want to delete this <b className='text-dark'>Sub-Category</b> ?</p>
                                        <p className='text-orange'>This will delete all <b className='text-danger'>Products</b> related to it !!</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn bg-skyblue" onClick={handleCloseDeleteModal}>Cancel</button>
                                        <button type="button" className="btn bg-pink" onClick={handleDeleteSubmit}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="d-flex flex-wrap justify-content-end mx-5">
                        <div className="p-3">
                            <button className="badge badge-secondary mr-1" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                                <i className="fas fa-arrow-left"></i>
                            </button>
                            <button className="badge badge-secondary ml-1" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} >
                                <i className="fas fa-arrow-right"></i>
                            </button>
                        </div>
                        <div className="p-3">
                            Page {currentPage} of {totalPages}
                        </div>
                    </div>
                    {alert.visible && (
                        <div className={`alert alert-${alert.type} border-dark`} role="alert">
                            <div className="iq-alert-icon text-danger">
                                <i className={`ri-${alert.type === 'success' ? 'check-line' : 'information-line'}`}></i>
                            </div>
                            <div className="iq-alert-text text-orange">{alert.message}</div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ListSubCategory;
