
const Signin = '/';
const SignUp = '/signup';
const ForgotPassword = "/forgotpassword";
const SuccessMail = "/successmail";
const DashBoard = "/dashboard";
const AddCategory = "/addcategory";
const ListCategory = "/listcategory";
const AddSubCategory = "/addsubcategory"
const ListSubCategory = "/listsubcategory"
const AddProduct = "/addproduct";
const ListProduct = "/listproduct";
const Customer = "/customer";
const Invoice = "/invoice";
const OrderOverview = "/orderoverview";
const ListOrder = "/listorder";
const CartOverview = "/cartoverview";
const DeliveryOverview = "/deliveryoverview"

export const BaseRoutes = {
    Signin,
    SignUp,
    ForgotPassword,
    SuccessMail,
    DashBoard,
    AddCategory,
    ListCategory,
    AddSubCategory,
    ListSubCategory,
    AddProduct,
    ListProduct,
    Customer,
    Invoice,
    OrderOverview,
    CartOverview,
    ListOrder,
    DeliveryOverview,
}